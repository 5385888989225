import { ParsedUrlQuery } from 'node:querystring'
import { container } from 'src/core/Shared/_di'

export const bookingConfirmation = (
  params: ParsedUrlQuery,
  itineraryNumber: string,
) => {
  const linkParams = {
    // transversales
    CBE: true, // always true

    page_language: window.document.documentElement.lang, // e.g.: "es" - string
    page_language_code: params.locale, // e.g.: "es-es" - string
    page_name: window.document.title, //  string
    page_pathname: window.document.location.pathname, //  string
    page_section: 'booking process', // string
    page_sub_section_old: 'confirmation (step 4)', // subsección de la página
    page_sub_section: 'confirmation',
    page_categorization: 'booking process>confirmation',
    page_url: window.document.URL, // string
    process: 'reservation', // reservation, modification or cancellation

    order_id: params.itineraryNumber, // order id - string

    adult: params.adult,
    booked_check_in_date: (params.arrive as string).replace(/-/g, ''),
    arrive: params.arrive,
    booked_check_out_date: (params.depart as string).replace(/-/g, ''),
    depart: params.depart,
    child: params.child,
    childages: params.childages,
    hotel: params.hotel,
    marketprice: params.marketprice,
    rooms: params.rooms,
    mealplans: params.mealplan,
    roomcode: params.roomcode,
    locale: params.locale,
    country: params.country,
    currency: params.currency,
    store: params.store,
    marketcampaign: params.marketcampaign,
    itineraryNumber: itineraryNumber,
    //theme: params.theme,
    //category: params.category,
  }

  container
    .resolve('logger')
    .info('Analytics/views: bookingConfirmation - link', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}

import { ParsedUrlQuery } from 'node:querystring'
import { container } from 'src/core/Shared/_di'

export const extras = (params: ParsedUrlQuery) => {
  const viewParams = {
    CBE: true,

    page_language: window.document.documentElement.lang,
    page_language_code: params.locale,
    page_name: window.document.title,
    page_pathname: window.document.location.pathname,
    page_section: 'booking process',
    page_sub_section: 'extra selection',
    page_sub_section_old: 'extra selection (step 2)',
    page_categorization: 'booking process>extra selection',
    page_url: window.document.URL,
    process: 'reservation',

    user_agent_property: window.navigator.userAgent,
    user_market_name: params.marketcampaign,
    user_culture: window.navigator.language,

    adult: params.adult,
    arrive: params.arrive,
    depart: params.depart,
    child: params.child,
    childages: params.childages,
    hotel: params.hotel,
    marketprice: params.marketprice,
    rooms: params.rooms,
    mealplans: params.mealplans,
    roomcode: params.roomcode,
    locale: params.locale,
    country: params.country,
    currency: params.currency,
    store: params.store,
    marketcampaign: params.marketcampaign,
  }

  container.resolve('logger').info('Analytics/views: extras', {
    viewParams,
    utag_data: window.utag_data,
  })
  window.utag.view(viewParams)
}

import { ParsedUrlQuery } from 'node:querystring'
import { container } from 'src/core/Shared/_di'
import { Time } from 'src/core/Shared/infrastructure/Time'

export const availableRooms = (params: ParsedUrlQuery) => {
  const viewParams = {
    // transversales
    CBE: true, // always true

    page_language: window.document.documentElement.lang, // e.g.: "es" - string
    page_language_code: params.locale, // e.g.: "es-es" - string
    page_name: window.document.title, //  string
    page_pathname: window.document.location.pathname, //  string
    page_section: 'booking process', // string
    page_sub_section_old: 'room selection (step 1)', // subsección de la página
    page_sub_section: 'room selection',
    page_categorization: 'booking process>room selection',
    page_url: window.document.URL, // string
    process: 'reservation', // reservation, modification or cancellation

    user_agent_property: window.navigator.userAgent, // string
    user_market_name: params.marketcampaign, // string
    user_culture: window.navigator.language, // user language and market combined. E.g.: es-es, es-mx, en-us, etc. - string

    adult: params.adult,
    arrive: params.arrive,
    booking_check_in_date: Time.fromString(params.arrive as string).format(
      'YYYYMMDD',
    ),
    depart: params.depart,
    booking_check_out_date: Time.fromString(params.depart as string).format(
      'YYYYMMDD',
    ),
    child: params.child,
    childages: params.childages,
    hotel: params.hotel,
    marketprice: params.marketprice,
    rooms: params.rooms,
    mealplans: params.mealplan,
    roomcode: params.roomcode,
    locale: params.locale,
    country: params.country,
    currency: params.currency,
    store: params.store,
    marketcampaign: params.marketcampaign,
    //theme: params.theme,
    //category: params.category,
  }

  container.resolve('logger').info('Analytics/views: availableRooms', {
    viewParams,
    utag_data: window.utag_data,
  })
  window.utag.view(viewParams)
}

import { FC } from 'react'
import styles from './BreakdownButton.module.scss'
import { Text, TextColor } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { classNames } from 'src/ui/utils/classnames'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import { Color } from 'src/ui/styles/settings'

interface Props {
  text: string
  chevronDirection?: 'up' | 'down'
  onClick: () => void
  className?: string
  color?: TextColor
  iconColor?: Color
}

export const BreakdownButton: FC<Props> = ({
  text,
  chevronDirection = 'down',
  onClick,
  className,
  color = 'dark',
  iconColor,
}) => (
  <button
    className={classNames(styles.showMoreButton, className)}
    onClick={onClick}
    type="button"
    tabIndex={0}
  >
    <Text
      fontStyle="link"
      color={color}
      className={classNames(styles.showMore, 'mr-xxs')}
      centered
    >
      {text}
    </Text>
    <Icon
      icon={ChevronIcon}
      size="l"
      color={iconColor}
      rotate={chevronDirection === 'down' ? 90 : -90}
    />
  </button>
)

import { ParsedUrlQuery } from 'node:querystring'
import { container } from 'src/core/Shared/_di'

export const personalData = (params: ParsedUrlQuery) => {
  const viewParams = {
    // transversales
    CBE: true, // always true

    availability_link: false,

    // Personal Data Specific Variables
    ecommerce_action: 'personal data',
    ecommerce_actionField_step: '2',
    ecommerce_action_field_list_gtag: 'step 2',
    ecommerce_action_field_step_gtag: '2',
    ecommerce_action_gtag: 'personal data',

    page_language: window.document.documentElement.lang, // e.g.: "es" - string
    page_language_code: params.locale, // e.g.: "es-es" - string
    page_name: window.document.title, //  string
    page_pathname: window.document.location.pathname, //  string
    page_section: 'booking process', // string
    page_sub_section_old: 'personal data (step 3)', // subsección de la página
    page_sub_section: 'personal data',
    page_categorization: 'booking process>personal data',
    page_url: window.document.URL, // string
    process: 'reservation', // reservation, modification or cancellation

    user_agent_property: window.navigator.userAgent, // string
    user_market_name: params.marketcampaign, // string
    user_culture: window.navigator.language, // user language and market combined. E.g.: es-es, es-mx, en-us, etc. - string

    adult: params.adult,
    arrive: params.arrive,
    depart: params.depart,
    child: params.child,
    childages: params.childages,
    hotel: params.hotel,
    marketprice: params.marketprice,
    rooms: params.rooms,
    mealplans: params.mealplan,
    roomcode: params.roomcode,
    locale: params.locale,
    country: params.country,
    currency: params.currency,
    store: params.store,
    marketcampaign: params.marketcampaign,
    //theme: params.theme,
    //category: params.category,
  }

  container.resolve('logger').info('Analytics/views: personalData', {
    viewParams,
    utag_data: window.utag_data,
  })
  window.utag.view(viewParams)
}
